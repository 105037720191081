import React from 'react';
import styled from 'styled-components';
import CbtListTableRow from './CbtListTableRow';
import CbtListMobile from 'app.feature/academy/cbt.list/component/CbtListMobile';

const CbtListTable = () => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>시행기관</th>
            <th>시험명</th>
            <th>총 회차</th>
            <th>총 문제 푼 인원 수</th>
            <th>조회수</th>
            <th>나의 관심시험 추가 수</th>
          </tr>
        </thead>
        <tbody>
          <CbtListTableRow />
        </tbody>
      </Table>
      <StyledWrapper>
        <CbtListMobile />
      </StyledWrapper>
    </>
  );
};

export default CbtListTable;

const StyledWrapper = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    .empty {
      text-align: center;
      padding: 20px;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #fff;
  display: table;
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }

  thead {
    background-color: #fff;
    border-top: 1px solid #666;
    border-bottom: 1px solid #ddd;
    height: 54px;

    th {
      font-size: 15px;
      color: #333;
      text-align: center;
    }
  }

  .empty {
    text-align: center;
    width: 100%;
    td {
      padding-top: 30px;
    }
  }
`;
