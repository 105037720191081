import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { projectConfigState } from 'recoil/Academy/projectConfigState';
import { useCurrentUser } from 'src/app.hooks/useCurrentUser';
import { useExamLikeHandler } from 'app.hooks/useExamLikeHandler';
import { convertExamQueryString } from 'src/app.feature/academy/module/convertExamQueryString';
import ExamApi from 'lib/api/exam';
import { TExams } from 'app.type/exams';
import { TPaginationData } from 'app.type/common';
import FavoriteIconSvg from 'src/app.feature/academy/component/icon/heart-icon-regular.svg';
import FavoriteIconSolidSvg from 'src/app.feature/academy/component/icon/heart-icon-solid.svg';
import { TExamsInfo } from 'app.type/examsInfo';

const CbtListTableRow = () => {
  const router = useRouter();
  const { user } = useCurrentUser();
  const { logo, handleLoginRedirect, handleLikeButtonClick } = useRecoilValue(projectConfigState);
  const queryClient = useQueryClient();

  const type = router.query?.type ? String(router.query.type) : '';
  const queryKey = [
    type ? `/apiV2/exams/${type}` : '/apiV2/exams',
    convertExamQueryString(router.query),
  ];

  const { data: exams = { totalCount: 0, nodes: [] } } = useQuery<TPaginationData<TExams>>({
    queryKey,
    queryFn: () =>
      type
        ? ExamApi.getExamsByType(type, convertExamQueryString(router.query))
        : ExamApi.getExamList(convertExamQueryString(router.query)),
  });

  const examList = exams.nodes;

  const { handleLikeExam } = useExamLikeHandler(queryKey);

  const updateExamInfo = (viewerHasLiked: boolean, examIdx: number) => {
    queryClient.setQueryData(queryKey, (oldData: any) => {
      if (!oldData) return oldData;

      if (Array.isArray(oldData.nodes)) {
        const updatedNodes = oldData.nodes.map((exam: any) =>
          exam.examIdx === examIdx
            ? {
                ...exam,
                likeCount: exam.likeCount + (viewerHasLiked ? -1 : 1),
                viewerHasLiked: !viewerHasLiked,
              }
            : exam
        );
        return { ...oldData, nodes: updatedNodes };
      }

      return oldData;
    });
  };

  const onLikeButtonClick = async (examIdx: number, viewerHasLiked: boolean) => {
    if (!user) {
      router.push(handleLoginRedirect(window.location.href));
      return;
    }

    await handleLikeExam(examIdx, viewerHasLiked);

    updateExamInfo(viewerHasLiked, examIdx);

    if (handleLikeButtonClick) {
      handleLikeButtonClick(examIdx);
    }
  };

  return (
    <>
      {examList?.length > 0 ? (
        examList.map((exam, index) => {
          return (
            <TableRow key={exam.examIdx}>
              <td className="institution">
                {exam?.examUrl ? (
                  <div className="img-wrapper">
                    <img src={exam.examUrl} alt={exam.examName} />
                    {exam?.institution ? exam.institution : '없음'}
                  </div>
                ) : (
                  <div className="empty-img-wrapper">
                    <div className="empty-img">
                      <img src={logo} alt={exam.examName} />
                    </div>
                    {exam?.institution ? exam.institution : '없음'}
                  </div>
                )}
              </td>
              <td className="title">
                <Link href={`/exam/detail/${exam.examIdx}`}>{exam.examName}</Link>
              </td>
              <td>{Number(exam.examDetailCount).toLocaleString()}</td>
              <td>{Number(exam.submitUserCount).toLocaleString()}</td>
              <td>{Number(exam.viewCount).toLocaleString()}</td>
              <td className="favorite">
                <button onClick={() => onLikeButtonClick(exam.examIdx, exam.viewerHasLiked)}>
                  {exam?.viewerHasLiked ? <FavoriteIconSolidSvg /> : <FavoriteIconSvg />}
                </button>
                <span>{exam.likeCount}</span>
              </td>
            </TableRow>
          );
        })
      ) : (
        <TableRow className="empty">
          <td colSpan={6}>검색 결과가 없습니다.</td>
        </TableRow>
      )}
    </>
  );
};

export default CbtListTableRow;

const TableRow = styled.tr`
  height: 100px;
  box-sizing: border-box;

  td {
    height: 100px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  .institution {
    .img-wrapper {
      img {
        width: 60px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    .empty-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .empty-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        > img {
          width: 80%;
          height: auto;
        }
      }
    }
  }

  .favorite {
    span {
      display: block;
      margin-top: 5px;
      line-height: initial;
      font-size: 16px;
      color: #999;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    > a {
      display: block;
      width: 100%;
      height: 100px;
      line-height: 100px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;
